import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router";
import Header from "../../components/header/Header";
import Posts from "../../components/posts/Posts";
import "./homepage.css";
import axios from "axios";
import { Context } from "../../Context/Context";
import FormChangePwd from "../../components/Form/FormChangePwd";

export default function Homepage() {
  const [posts, setPosts] = useState([]);
  const { search } = useLocation();
  const { toggleForm, user, dispatch } = useContext(Context);
  const [checkUser, setCheckUser] = useState();
  const [active, setActive] = useState("");

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        "https://backend.hoanglecf.vn/api/posts" + search
        // "http://localhost:5000/api/posts" + search
      );
      setPosts(res.data);
    };
    fetchPosts();
  }, [search]);

  useEffect(() => {
    let isMounted = true;
    const getUser = async () => {
      try {
        const res = await axios.get(
          "https://backend.hoanglecf.vn/api/users/64168524e0c05453f8f58a20"
          // "http://localhost:5000/api/users/66d416ce627f7df492896204"
        );
        if (isMounted) {
          setCheckUser(res.data.username);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getUser();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const checkUserMismatch = () => {
      if (checkUser !== user.username) {
        dispatch({ type: "LOGOUT" });
      }
    };

    const timer = setTimeout(checkUserMismatch, 2000);

    return () => clearTimeout(timer);
  }, [checkUser, user.username, dispatch]);

  useEffect(() => {
    if (toggleForm) {
      document.body.style.overflow = "hidden";
      window.scrollTo(0, 0);
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [toggleForm]);

  return (
    <>
      {/* <Header /> */}
      <div className="home_btn_filter">
        <button
          className={active === "" ? "active" : ""}
          onClick={() => setActive("")}
        >
          Tất cả
        </button>
        <button
          className={active === "crossFire" ? "active" : ""}
          onClick={() => setActive("crossFire")}
        >
          CrossFire
        </button>
        <button
          className={active === "valorant" ? "active" : ""}
          onClick={() => setActive("valorant")}
        >
          Valorant
        </button>
        <button
          className={active === "guide" ? "active" : ""}
          onClick={() => setActive("guide")}
        >
          Hướng dẫn
        </button>
      </div>
      <div className="home">
        {active === "crossFire" ||
        active === "valorant" ||
        active === "guide" ? (
          <Posts posts={posts.filter((post) => post.thumb === active)} />
        ) : (
          <Posts posts={posts} />
        )}
      </div>
      {toggleForm && <FormChangePwd />}
    </>
  );
}
