import axios from "axios";
import { useContext, useRef, useState, useEffect } from "react";
import { Context } from "../../Context/Context";
import "./Login.css";

export default function Login() {
  const userRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState(false);
  const { dispatch, isFetching } = useContext(Context);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post(
        "https://backend.hoanglecf.vn/api/auth/login",
        // "http://localhost:5000/api/auth/login",
        {
          username: userRef.current.value,
          password: passwordRef.current.value,
        }
      );
      if (isMounted.current) {
        dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
        setError(false);
      }
    } catch (err) {
      if (isMounted.current) {
        dispatch({ type: "LOGIN_FAILURE" });
        setError(true);
      }
    }
  };

  return (
    <div className="login">
      <span className="loginTitle">Login</span>
      <form className="loginForm" onSubmit={handleSubmit}>
        <label>Username</label>
        <input
          type="text"
          className="loginInput"
          placeholder="Enter your username..."
          ref={userRef}
        />
        <label>Password</label>
        <input
          type="password"
          className="loginInput"
          placeholder="Enter your password..."
          ref={passwordRef}
        />
        <button className="loginButton" type="submit" disabled={isFetching}>
          Login
        </button>
        {error && (
          <p style={{ color: "red", marginTop: 10 }}>Somethings went wrong!</p>
        )}
      </form>
    </div>
  );
}
