import { Link } from "react-router-dom";
import "./post.css";

export default function Post({ post }) {
  const PF = "https://backend.hoanglecf.vn/images/";
  // const PF = "http://localhost:5000/images/";

  return (
    <Link to={`/post/${post._id}`} className="link">
      <div className="post">
        {post.photo && <img className="postImg" src={PF + post.photo} alt="" />}
        <div className="postInfo">
          <p className="postTitle">
            {post?.desc?.length > 20 ? (
              <span className="highlightTitle">Mục:</span>
            ) : (
              <span className="highlightTitle">MS - Tên acc:</span>
            )}
            <br />
            {post.title}
          </p>
          <p className="postTitle">
            {post?.desc?.length > 20 ? (
              <span className="highlightTitle">Link: </span>
            ) : (
              <span className="highlightTitle">Acc chuyên:</span>
            )}
            <br />
            {post.desc.length > 20
              ? `${post.desc.substring(0, 20)}...`
              : post.desc}
          </p>
          <p className="postTitle">
            {post?.desc?.length > 20 ? (
              <span className="highlightTitle">Mô tả: </span>
            ) : (
              <span className="highlightTitle">Giá thuê:</span>
            )}
            <br />
            {post.price.length > 20
              ? `${post.price.substring(0, 20)}...`
              : post.price}
          </p>
        </div>
      </div>
    </Link>
  );
}
