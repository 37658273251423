import { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../Context/Context";
import "./TopBar.css";

export default function TopBar() {
  const { user, dispatch, ToggleSetPwd } = useContext(Context);

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  };
  return (
    <div className="top">
      <div className="topLeft">
        <ul className="topList">
          <li className="topListItem">
            <Link className="link" to="/">
              Home
            </Link>
          </li>
          <li className="topListItem">
            <Link className="link" to="/write">
              Tạo tài khoản
            </Link>
          </li>
          <li className="topListItem" onClick={ToggleSetPwd}>
            {user && "Đổi mật khẩu"}
          </li>
          <li className="topListItem fontColor_red" onClick={handleLogout}>
            {user && "Đăng xuất"}
          </li>
        </ul>
      </div>
      <div className="topRight">
        {user ? (
          <img
            className="topImg"
            src="https://images.unsplash.com/photo-1517849845537-4d257902454a"
            alt=""
          />
        ) : (
          <ul className="topList">
            <li className="topListItem">
              <Link className="link" to="/login">
                LOGIN
              </Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}
