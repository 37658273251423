import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Context } from "../../Context/Context";
import "./singlePost.css";

export default function SinglePost() {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const [post, setPost] = useState({});
  // const PF = "http://localhost:5000/images/";
  const PF = "https://backend.hoanglecf.vn/images/";
  const { user } = useContext(Context);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [price, setPrice] = useState("");
  const [listImages, setListImages] = useState([]);
  const [updateMode, setUpdateMode] = useState(false);
  const [thumb, setThumb] = useState("");

  console.log("listImages", listImages);
  useEffect(() => {
    const getPost = async () => {
      const res = await axios.get(
        "https://backend.hoanglecf.vn/api/posts/" + path
        // "http://localhost:5000/api/posts/" + path
      );
      setPost(res.data);
      setTitle(res.data.title);
      setDesc(res.data.desc);
      setPrice(res.data.price);
      setListImages(res.data.listImages);
      setThumb(res.data.thumb);
    };
    getPost();
  }, [path]);

  const handleDelete = async () => {
    try {
      await axios.delete(
        `https://backend.hoanglecf.vn/api/posts/${post._id}`,
        // `http://localhost:5000/api/posts/${post._id}`,
        {
          data: { username: user.username },
        }
      );
      window.location.replace("/");
    } catch (err) {}
  };

  const handleUpdate = async () => {
    try {
      await axios.put(
        `https://backend.hoanglecf.vn/api/posts/${post._id}`,
        // `http://localhost:5000/api/posts/${post._id}`,
        {
          username: user.username,
          title,
          desc,
          price,
          listImages,
        }
      );
      setUpdateMode(false);
    } catch (err) {}
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...listImages];
    newFiles.splice(index, 1);
    setListImages(newFiles);
  };

  return (
    <div className="singlePost">
      <div className="singlePostWrapper">
        <div className="singlePostWrapper_info">
          <div className="singlePostWrapper_info-left">
            <div className="singlePostEdit">
              {post.photo && (
                <img src={PF + post.photo} alt="" className="singlePostImg" />
              )}
              <div className="singlePostEdit_btn">
                {updateMode ? (
                  <button className="singlePostButton" onClick={handleUpdate}>
                    Update
                  </button>
                ) : (
                  <button className="btn" onClick={() => setUpdateMode(true)}>
                    <i className="singlePostIcon far fa-edit"></i>
                  </button>
                )}
                {thumb === "guide" ? (
                  ""
                ) : (
                  <button className="btn delete" onClick={handleDelete}>
                    <i className="singlePostIcon far fa-trash-alt"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="singlePostWrapper_info-right">
            {updateMode ? (
              <p className="singlePostTitle">
                {thumb === "guide" ? (
                  <span>Mục: </span>
                ) : (
                  <span>Acc chuyên:</span>
                )}
                <br />
                <i class="fas fa-pen"></i>
                <input
                  type="text"
                  value={title}
                  className="singlePostTitleInput"
                  autoFocus
                  onChange={(e) => setTitle(e.target.value)}
                />
              </p>
            ) : (
              <p className="singlePostTitle">
                {thumb === "guide" ? (
                  <span>Mục: </span>
                ) : (
                  <span>Acc chuyên:</span>
                )}
                <br />
                {title}
              </p>
            )}
            {updateMode ? (
              <p className="singlePostDesc">
                {thumb === "guide" ? (
                  <span>Link: </span>
                ) : (
                  <span>Acc vip:</span>
                )}
                <br />
                <i class="fas fa-pen"></i>
                <input
                  className="singlePostDescInput"
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </p>
            ) : (
              <p className="singlePostDesc">
                {thumb === "guide" ? (
                  <span>Link: </span>
                ) : (
                  <span>Acc vip:</span>
                )}
                <br />
                {desc}
              </p>
            )}
            {updateMode ? (
              <p className="singlePostDesc">
                {thumb === "guide" ? (
                  <span>Mô tả: </span>
                ) : (
                  <span>Giá thuê:</span>
                )}
                <br />
                <i class="fas fa-pen"></i>
                <input
                  className="singlePostDescInput"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </p>
            ) : (
              <p className="singlePostDesc">
                {thumb === "guide" ? (
                  <span>Mô tả: </span>
                ) : (
                  <span>Giá thuê:</span>
                )}
                <br />
                {price}₫
              </p>
            )}
          </div>
        </div>

        {updateMode ? (
          <div className="imagePreview">
            {listImages.map((img, index) => (
              <div key={index} className="imagePreviewItem">
                <img src={PF + img} alt="" />
                <div className="imagePreviewOverlay">
                  <button
                    type="button"
                    className="imagePreviewRemoveButton"
                    onClick={() => handleRemoveFile(index)}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="imagePreview">
            {listImages.map((img, index) => (
              <div key={index} className="imagePreviewItem">
                <img src={PF + img} alt="" />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
