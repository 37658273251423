import { useState, useRef, useContext, useEffect } from "react";
import "./FormChangePwd.css";
import { Context } from "../../Context/Context";
import axios from "axios";

const FormChangePwd = () => {
  const formRef = useRef(null);
  const { user, dispatch, ToggleSetPwd } = useContext(Context);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [changeFailed, setChangeFailed] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "UPDATE_START" });

    const updatedUser = {
      userId: user._id,
      username: user.username,
      email: user.email,
      password,
    };

    try {
      if (password === confirmPassword) {
        const res = await axios.put(
          "https://backend.hoanglecf.vn/api/users/" + user._id,
          // "http://localhost:5000/api/users/" + user._id,
          updatedUser
        );

        setChangeSuccess(true);
        setChangeFailed(false);
        dispatch({ type: "UPDATE_SUCCESS", payload: res.data });
      } else {
        setChangeFailed(true);
        setChangeSuccess(false);
      }
    } catch (err) {
      dispatch({ type: "UPDATE_FAILURE" });
      setChangeFailed(true);
      setChangeSuccess(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        ToggleSetPwd();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ToggleSetPwd]);

  return (
    <div className="main_form">
      <div ref={formRef} className="formbg">
        <h2 className="formbg-title">Đổi mật khẩu</h2>
        <form id="stripe-login" onSubmit={handleSubmit}>
          <div className="form_content">
            <div className="change-password_title">
              <label htmlFor="password">Nhập mật khẩu</label>
            </div>
            <input
              className="change-password_input"
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="change-password_title">
              <label htmlFor="re-password">Nhập lại mật khẩu</label>
            </div>
            <input
              className="change-password_input"
              type="password"
              name="re-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div className="field">
            <button className="btn-submit" onClick={ToggleSetPwd}>
              Hủy
            </button>
            <input
              className="btn-submit"
              type="submit"
              name="submit"
              value="Đổi"
            />
          </div>
          {changeSuccess && (
            <p style={{ color: "green", marginTop: 10 }}>
              Đổi mật khẩu thành công
            </p>
          )}
          {changeFailed && (
            <p style={{ color: "red", marginTop: 10 }}>
              Đổi mật khẩu không thành công
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default FormChangePwd;
