import { useState, useContext } from "react";
import { Context } from "../../Context/Context";
import axios from "axios";
import "./write.css";

export default function Write() {
  const [title, setTitle] = useState();
  const [desc, setDesc] = useState();
  const [price, setPrice] = useState();
  const [file, setFile] = useState(null);
  const [listImages, setListImages] = useState([]);
  const [type, setType] = useState();
  const { user } = useContext(Context);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setListImages([...listImages, ...selectedFiles]);
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...listImages];
    newFiles.splice(index, 1);
    setListImages(newFiles);
  };

  const onChangeValueType = (e) => {
    setType(e.target.value);
    console.log("check value of options: ", e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newPost = {
      username: user.username,
      title,
      desc,
      price,
      thumb: type,
      listImages: [],
    };

    const imageData = [];
    listImages.forEach((file) => {
      const data = new FormData();
      const name = Date.now() + file.name;
      data.append("name", name);
      data.append("file", file);
      newPost.listImages.push(name);
      imageData.push({ name, file });
    });
    console.log("upload Images: ", imageData);

    // Wait for all the image uploads to complete
    const uploadPromises = imageData.map(async (data) => {
      const xxx = new FormData();
      xxx.append("name", data.name);
      xxx.append("file", data.file);
      await axios.post("https://backend.hoanglecf.vn/api/upload", xxx);
      // await axios.post("http://localhost:5000/api/upload", xxx);
    });
    await Promise.all(uploadPromises);

    console.log("uploadPromises: ", uploadPromises);

    if (file) {
      const data = new FormData();
      const filename = Date.now() + file.name;
      data.append("name", filename);
      data.append("file", file);
      newPost.photo = filename;
      console.log("singleImage: ", filename);
      try {
        await axios.post("https://backend.hoanglecf.vn/api/upload", data);
        // await axios.post("http://localhost:5000/api/upload", data);
      } catch (err) {}
    }
    try {
      const res = await axios.post(
        "https://backend.hoanglecf.vn/api/posts",
        // "http://localhost:5000/api/posts",
        newPost
      );
      console.log(123, res);
      window.location.replace("/post/" + res.data._id);
    } catch (err) {}
  };

  return (
    <div className="write">
      <form className="writeForm" onSubmit={handleSubmit}>
        <div className="writeForm_fileInput">
          <label htmlFor="fileInput">
            <i className="writeUpload fas fa-plus"> Tải ảnh</i>
          </label>
          <input
            id="fileInput"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => setFile(e.target.files[0])}
          />
        </div>
        <div>
          {file && (
            <img className="writeImg" src={URL.createObjectURL(file)} alt="" />
          )}
        </div>
        <p>Chọn loại account: </p>
        <div className="writeFormGroupOptions">
          <input
            type="radio"
            id="cf"
            name="type_account"
            value="crossFire"
            onChange={onChangeValueType}
          />
          <label for="cf">CrossFire</label>
          <input
            type="radio"
            id="valo"
            name="type_account"
            value="valorant"
            onChange={onChangeValueType}
          />
          <label for="valo">Valorant</label>
        </div>
        <p>Nhập Mã số: </p>
        <div className="writeFormGroup">
          <i class="fas fa-pen"></i>
          <input
            className="writeInput"
            placeholder="2323 - name "
            type="text"
            autoFocus={true}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <p>Nhập thông tin Acc chuyên: </p>
        <div className="writeFormGroup">
          <i class="fas fa-pen"></i>
          <textarea
            className="writeInput writeText"
            placeholder="78 vip - vip 7"
            type="text"
            autoFocus={true}
            onChange={(e) => setDesc(e.target.value)}
          />
        </div>
        <p>Nhập giá Acc: </p>
        <div className="writeFormGroup">
          <i class="fas fa-pen"></i>
          <textarea
            className="writeInput writeText"
            placeholder="10.000"
            type="text"
            autoFocus={true}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <p>Thêm ảnh chi tiết: </p>
        <div className="writeFormGroup">
          <label htmlFor="fileInputImgs">
            <i className="writeIcon fas fa-plus"></i>
          </label>
          <input
            id="fileInputImgs"
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
            multiple
          />
        </div>
        <div className="imagePreview">
          {listImages.map((file, index) => (
            <div key={index} className="imagePreviewItem">
              <img src={URL.createObjectURL(file)} alt="" />
              <div className="imagePreviewOverlay">
                <button
                  type="button"
                  className="imagePreviewRemoveButton"
                  onClick={() => handleRemoveFile(index)}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
          ))}
        </div>
        <button className="writeSubmit" type="submit">
          Tạo Tài Khoản
        </button>
      </form>
    </div>
  );
}
